<template>
  <div class="content__body">
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
      <div slot="manager.name" slot-scope="props" class="table-default__left">
        {{ props.row.manager.name }}
      </div>
      <div slot="manager.email" slot-scope="props">
        {{ props.row.manager.email ? props.row.manager.email : '-' }}
      </div>
      <div slot="manager.phone_number" slot-scope="props">
        {{ props.row.manager.phone_number ? props.row.manager.phone_number : '-' }}
      </div>
    </v-client-table>
  </div>
</template>

<script>
export default {
  name: 'Managers',
  data() {
    return {
      table: {
        items: [],
        columns: ['manager.code_1c', 'manager.name', 'manager.email', 'manager.phone_number'],
        options: {
          headings: {
            'manager.code_1c': 'Код 1с',
            'manager.name': 'ФИО',
            'manager.email': 'Электронная почта',
            'manager.phone_number': 'Телефон',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {},
      searchResult: null,
      searchOptions: [],
    }
  },
  mounted() {
    this.$store.dispatch('client/GET_MANAGERS', this.$route.params.id)
      .then(response => {
        this.table.items = response.data
      })
      .catch(() => {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Ошибка получения данных'
        })
      })
  }
}
</script>
